<template>
  <div class="produce-map-page">
    <div class="sel-farm">
      <Select v-model="farmId" style="width: 200px" placeholder="选择养殖场">
        <Option
          v-for="item in farms"
          :value="item.sourceId"
          :key="item.sourceId"
          >{{ item.mapName }}
        </Option>
      </Select>
    </div>
    <produce-map :farms="farms"></produce-map>
  </div>
</template>

<script>
import produceMap from "./map";
import { mapState } from "vuex";
export default {
  name: "",
  components: {
    produceMap,
  },
  data() {
    return {
      currentFarm: null,
      farmId: "",
    };
  },
  computed: {
    ...mapState({
      farms: (state) => state.map.farms,
    }),
  },
  watch: {
    farmId(n) {
      this.currentFarm = this.farms.filter((item) => item.sourceId == n)[0];
    },
  },
  methods: {},
  mounted() {
    this.$store.dispatch("getFarms", { farmType: "2" });
    if (+this.roleid === 40) {
      this.currentFarm = this.farms[0]
    }

  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
